import React, { FC, useEffect } from 'react';
import { PageLayout } from '../components/layout';
import { isMobile as isMobileDevice } from 'react-device-detect';
import { useMobileAppUrl } from '../hooks/use-mobile-app-url';

const DownloadApp: FC = () => {
  /**
   * Hooks
   */

  const mobileAppUrl = useMobileAppUrl();
  useEffect(() => {
    if (!isMobileDevice) {
      return;
    }
    if (typeof window === 'undefined') {
      return;
    }
    if (!mobileAppUrl) {
      return;
    }
    window.location.replace(mobileAppUrl);
  }, [mobileAppUrl]);

  /**
   * DOM
   */

  return <PageLayout bgCls="bg-accent-light" showMobileAppPrompt={1}></PageLayout>;
};

export default DownloadApp;
